import L from 'leaflet'
import Vue from 'vue'

const MAX_ZOOM = 15
const TILE_LAYERS = [
  {
    name: 'WorldTerrainBase',
    group: true,
    items: [
      {
        maxZoom: MAX_ZOOM,
        attribution: '&copy; Esri',
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}'
      },
      {
        maxZoom: MAX_ZOOM,
        attribution: '',
        url: 'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Reference_Overlay/MapServer/tile/{z}/{y}/{x}'
      }
    ]
  },
  {
    name: 'OpenStreetMap',
    maxZoom: MAX_ZOOM,
    attribution: '© <a href="/copyright">Contributeurs de OpenStreetMap</a>. Tiles courtesy of <a href="http://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>',
    url: 'https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png'
  },
  {
    name: 'Plan',
    maxZoom: MAX_ZOOM,
    attribution: 'Sources: Esri, HERE, DeLorme, USGS, Intermap, increment P Corp., NRCAN, Esri Japan, METI, Esri China (Hong Kong), <br>Esri (Thailand), TomTom, MapmyIndia, &copy; OpenStreetMap contributors, and the GIS User Community',
    url: 'https://server.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
  },
  {
    name: 'Terrain',
    maxZoom: MAX_ZOOM,
    attribution: '&copy; Esri, HERE, DeLorme, TomTom, Intermap, increment P Corp., GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, <br>Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), swisstopo, MapmyIndia, © OpenStreetMap contributors, and the GIS User Community',
    url: 'https://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
  },
  {
    name: 'Terrain 2',
    maxZoom: MAX_ZOOM,
    attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.',
    url: 'https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png'
  },
  {
    name: 'Toner',
    maxZoom: MAX_ZOOM,
    attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.',
    url: 'https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png'
  },
  {
    name: 'Satellite',
    maxZoom: MAX_ZOOM,
    attribution: '&copy; Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, <br>USGS, AEX, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
  },
  {
    name: 'Watercolor',
    maxZoom: MAX_ZOOM,
    attribution: '<a id="home-link" target="_top" href="http://maps.stamen.com/">Map tiles</a> by <a target="_top" href="http://stamen.com">Stamen Design</a>, under <a target="_top" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data © <a target="_top" href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>.',
    url: 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg'
  }
]

type ScaleItem = [number, number[], string?];
export type GenericScale = ScaleItem[];

// export const SCALE_MAG_COLOR: GenericScale = [
//   [1, [250, 255, 0], '≤1'],
//   [2, [255, 170, 0], '2'],
//   [4, [255, 85, 0], '4'],
//   [6, [255, 0, 0], '6'],
//   [8, [170, 0, 0], '≥8']
// ]
export const SCALE_MAG_COLOR: GenericScale = [
  [1, [250, 255, 0], '≤1'],
  [2, [252, 211, 0], '2'],
  [4, [255, 123, 0], '4'],
  [6, [255, 0, 0], '6'],
  [8, [170, 0, 0], '≥8']
]
/*
const MAG_FACTOR = 1.3 * 0.25
export const SCALE_MAG: GenericScale = [
  [0, [4 * MAG_FACTOR]],
  [1, [4 * MAG_FACTOR]],
  [2, [7 * MAG_FACTOR]],
  [3, [10 * MAG_FACTOR]],
  [4, [13 * MAG_FACTOR]],
  [5, [16 * MAG_FACTOR]],
  [6, [16 * MAG_FACTOR]],
  [7, [18 * MAG_FACTOR]],
  [8, [18 * MAG_FACTOR]],
  [9, [20 * MAG_FACTOR]]
]
*/
const MAG_FACTOR = 1.2
export const SCALE_MAG: GenericScale = [
  [0, [0 * 1.0 * MAG_FACTOR]],
  [1, [1 * 1.1 * MAG_FACTOR]],
  [2, [2 * 1.2 * MAG_FACTOR]],
  [3, [3 * 1.3 * MAG_FACTOR]],
  [4, [4 * 1.4 * MAG_FACTOR]],
  [5, [5 * 1.5 * MAG_FACTOR]],
  [6, [6 * 1.6 * MAG_FACTOR]],
  [7, [7 * 1.7 * MAG_FACTOR]],
  [8, [8 * 1.8 * MAG_FACTOR]],
  [9, [9 * 1.9 * MAG_FACTOR]]
]

export const SCALE_DEPTH_COLOR: GenericScale = [
  [0, [29, 140, 215], '0'],
  [25e3, [81, 108, 195], '25'],
  [50e3, [142, 70, 172], '50'],
  [75e3, [203, 32, 149], '75'],
  [100e3, [255, 0, 129], '≥100']
]

export const LATENCY_SCALE: GenericScale = [
  [0, [119, 255, 119], '0s'], // green
  [300, [255, 242, 0], '5m'], // yellow
  [3600, [255, 170, 0], '1h'], // orange
  [86400, [255, 0, 0], '1j'], // red
  [604800, [134, 72, 249], '7j'], // purple
  [2592000, [0, 131, 255], '30j'], // blue
  [5184000, [0, 0, 0], '60j'] // black
]

// export const LOCAL_AREA_SHAPE: L.LatLngBoundsExpression = [[45, 4], [45, 8.5], [44, 8.5], [44, 9.75], [41, 9.75], [41, 4]]
export const LOCAL_AREA_SHAPE: L.LatLngBoundsExpression = [
  [-33.5, -75],
  [-28.5, -75],
  [-28.5, -67.5],
  [-33.5, -67.5],
  [-33.5, -75]
]

export const prettyDuration = (duration: number, seconds = true) => {
  const sign = duration < 0 ? '-' : ''
  duration = Math.abs(duration)
  const result = []
  const nbDays = Math.floor(duration / 86400e3)
  if (nbDays >= 1) {
    result.push(nbDays + 'j')
    duration -= (nbDays * 86400e3)
  }
  const nbHours = Math.floor(duration / 3600e3)
  if (nbHours >= 1) {
    result.push(nbHours + 'h')
    duration -= (nbHours * 3600e3)
  }
  const nbMin = Math.floor(duration / 60e3)
  if (nbMin >= 1) {
    result.push(nbMin + 'm')
    duration -= (nbMin * 60e3)
  }
  if (seconds === true && duration >= 0) {
    result.push(Math.floor(duration / 1e3) + 's')
  }
  return sign + result.join(' ')
}

const DstTimeRangeCache: {[year: number]: { endDST: Date; beginDST: Date }} = {}
const getLocalTimeShift = (isoTime: string) => {
  const t = new Date(isoTime)
  const year = t.getFullYear()
  if (DstTimeRangeCache[year]) {
    return t >= DstTimeRangeCache[year].endDST && t < DstTimeRangeCache[year].beginDST ? -4 : -3
  }
  let endDST = new Date(`${t.getFullYear()}-04-01T03:00:00Z`)
  const daysToAddForEndDST = (endDST.getDay() > 0 ? 7 - endDST.getDay() : 7)
  endDST = new Date(endDST.getTime() + 86400e3 * daysToAddForEndDST)
  let beginDST = new Date(`${t.getFullYear()}-09-01T04:00:00Z`)
  const daysToAddForBeginDST = (endDST.getDay() > 0 ? 7 - endDST.getDay() : 7)
  beginDST = new Date(beginDST.getTime() + 86400e3 * daysToAddForBeginDST)
  DstTimeRangeCache[year] = { endDST, beginDST }
  return t >= endDST && t < beginDST ? -4 : -3
}

export const toLocalDateTime = (isoTime: string) => {
  const timeShift = getLocalTimeShift(isoTime)
  let t = new Date(isoTime)
  t = new Date(t.getTime() + timeShift * 3600e3)
  return {
    pretty: t.toISOString().slice(0, 19).replace('T', ' '),
    tz: timeShift < 0 ? `GMT${timeShift}` : `GMT+${timeShift}`
  }
}

export function createMap (
  mapCanvas: Element | Vue | (Element | Vue)[],
  mapOptions: Record<string, any> = {},
  disclaimer?: L.Control | null,
  legendControl?: L.Control
): L.Map {
  const baseLayers: Record<string, L.TileLayer|L.LayerGroup> = {}
  for (const layer of TILE_LAYERS) {
    if (layer.group) {
      const group = []
      for (const item of layer.items) {
        group.push(L.tileLayer(item.url, { attribution: item.attribution, maxZoom: item.maxZoom }))
      }
      baseLayers[layer.name] = L.layerGroup(group)
    } else {
      if (layer.url != null) {
        baseLayers[layer.name] = L.tileLayer(layer.url, { attribution: layer.attribution, maxZoom: layer.maxZoom })
      }
    }
  }
  const map = L.map(<HTMLElement>mapCanvas, mapOptions)

  const worldterrainbase = baseLayers.WorldTerrainBase
  const worldtopomap = baseLayers.Terrain
  map.on('zoomend', (ev) => {
    if (map.hasLayer(worldterrainbase) && map.getZoom() >= 10) {
      map.removeLayer(worldterrainbase)
      map.addLayer(worldtopomap)
    } else if (map.hasLayer(worldtopomap) && map.getZoom() < 10) {
      map.removeLayer(worldtopomap)
      map.addLayer(worldterrainbase)
    }
  })
  worldterrainbase.addTo(map)
  if (disclaimer) {
    disclaimer.addTo(map)
  }
  if (legendControl) {
    legendControl.addTo(map)
  }
  L.control.layers(baseLayers).addTo(map)
  return map
}

type BeachballEngineWASMExports = {
  setBoxSize: (s: number) => void;
  init: (n: number) => void;
  setFocal: (s: number, d: number, r: number) => number;
  getResult: (i: number) => number;
}
export class BeachballEngine {
  boxSize: number
  nbPoint: number
  pointSize: number
  wasmURI: string
  module: null | WebAssembly.WebAssemblyInstantiatedSource
  ctx: null | CanvasRenderingContext2D

  constructor (boxSize: number, nbPoint: number, pointSize: number, wasmURI: string) {
    this.boxSize = boxSize
    this.nbPoint = nbPoint
    this.pointSize = pointSize
    this.wasmURI = wasmURI
    this.module = null
    this.ctx = null
  }

  init () {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas')
      canvas.width = canvas.height = this.boxSize
      this.ctx = canvas.getContext('2d')
      const importObject = {
        env: {
          abort (_msg: string, _file: string, line: number, column: number) {
            console.error('abort called at index.ts:' + line + ':' + column)
          }
        }
      }
      WebAssembly.instantiateStreaming(fetch(this.wasmURI), importObject).then(wasmModule => {
        this.module = wasmModule
        const { setBoxSize, init } = wasmModule.instance.exports as BeachballEngineWASMExports
        setBoxSize(this.boxSize)
        init(this.nbPoint)
        resolve(null)
      })
    })
  }

  getFocalImage (s: number, d: number, r: number, c = 'blue') {
    if (this.ctx == null || this.module == null) {
      return
    }
    this.ctx.clearRect(0, 0, this.boxSize, this.boxSize)
    const { setFocal, getResult } = this.module.instance.exports as BeachballEngineWASMExports
    const l = setFocal(s, d, r)
    const center = (this.boxSize + this.pointSize) / 2
    const radius = getResult(0)
    this.ctx.strokeStyle = 'black'
    this.ctx.fillStyle = 'white'
    this.ctx.beginPath()
    this.ctx.arc(center, center, radius, 0, 2 * Math.PI)
    this.ctx.fill()
    this.ctx.fillStyle = c
    for (let i = 1; i < l; i++) {
      const x = getResult(i)
      if (x === 0) {
        this.ctx.fillStyle = 'black'
        continue
      }
      const y = getResult(++i)
      this.ctx.fillRect(x, y, this.pointSize, this.pointSize)
    }
    this.ctx.beginPath()
    this.ctx.arc(center, center, radius, 0, 2 * Math.PI)
    this.ctx.stroke()
    return this.ctx.canvas.toDataURL()
  }
}

export function toRGB (color: number[]) {
  return `rgb(${color.map(c => Math.floor(c)).join(',')})`
}

export function applyScale (v: number, cs: GenericScale) {
  if (v <= cs[0][0]) {
    return cs[0][1]
  }
  if (v >= cs[cs.length - 1][0]) {
    return cs[cs.length - 1][1]
  }
  let i
  for (i = 0; cs[i][0] < v; i++);
  const r = (v - cs[i - 1][0]) / (cs[i][0] - cs[i - 1][0])
  const result = []
  for (let j = 0; j < cs[i][1].length; j++) {
    result.push(cs[i - 1][1][j] + r * (cs[i][1][j] - cs[i - 1][1][j]))
  }
  return result
}
